<template>
    <div class="wrok-theme-create">
        <div class="manage-wrapper">
            <div class="content">
                <div class="myTabs">
                    <el-tabs v-model="activeName">
                        <el-tab-pane :label="$route.query.isEdit=='true' ? '编辑报告' : '报告'" name="kimi">
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="box flex-between">
                    <div class="user_box flex-column" v-if="$route.query.reportType == 3">
                        <div class="title">学员名单</div>
                        <el-input placeholder="请输入学员姓名" size="small" prefix-icon="el-icon-search" v-model="queryName"
                            @input="nameChange">
                        </el-input>
                        <div class="user_item flex-align" :class="{ 'user_item_active': user_index == index }"
                            v-for="(item, index) in stuList" :key="index" @click="selectUser(item, index)">
                            <img src="@/assets/images/home/avatar.png" alt="">
                            <span class="wes">{{ item.name }}</span>
                        </div>
                        <div class="pagination">
                            <el-pagination layout="prev,pager,next" small :total="allSize" :pager-count="5"
                                :page-size="pagesize" :current-page.sync="currentPage"
                                @current-change="handleCurrentChange">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="kimi_box">
                        <div class="kimi_content" v-loading="loading">
                            <div class="title">{{ $route.query.title }}</div>
                            <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="kimi_detail"
                                v-if="$route.query.isEdit=='true'">
                            </el-input>
                            <vue-markdown style="line-height: 30px;" :source="kimi_detail" v-else></vue-markdown>
                            <!-- <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="kimi_detail" readonly v-else>
                            </el-input> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-align-around" style="margin-top: 10px;" v-if="$route.query.isEdit == 'true'">
                <el-button type="primary" @click="edit">完成</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import VueMarkdown from 'vue-markdown'
export default {
    components: {
        VueMarkdown
    },
    data() {
        return {
            activeName: 'kimi',
            userInfo: this.$store.state.user,
            loading: false,
            input: '',
            queryName: '',
            type: '',
            allSize: 0,
            user_index: 0,
            history_index: 0,
            historyList: [],
            pagesize: 7,
            currentPage: 1,
            stuList: [],
            currentUserId: '',
            parentId: '',
            kimi_detail: ''
        }
    },
    methods: {
        handleCurrentChange(page) {
            this.currentPage = page;
            this.user_index = 0;
            this.reportUserList();
        },
        // 选择用户
        selectUser(item, index) {
            this.user_index = index;
            this.currentUserId = item.id;
            this.reportAiLast()
        },
        // 获取学员名单
        async reportUserList() {
            let data = {
                reportId: this.$route.query.reportId,
                page: this.currentPage,
                limit: this.pagesize,
                queryName: this.queryName
            }
            let resData = await this.$Api.kimi.reportUserList(data);
            console.log('学员列表:', resData);
            this.allSize = resData.data.total;
            this.stuList = resData.data.records;
            this.currentUserId = resData.data.records[0].id;
            this.reportAiLast();
        },
        nameChange(name) {
            this.queryName = name.trim();
            this.currentPage = 1;
            this.reportUserList()
        },
        // 获取报告
        async reportAiLast() {
            let data = {
                reportId: this.$route.query.reportId,
                userId: this.$route.query.reportType == 3 ? this.currentUserId : ''
            }
            let resData = await this.$Api.kimi.reportAiLast(data);
            // console.log('获取报告:',resData);
            this.kimi_detail = resData.data.content;
            this.parentId = resData.data.id;
        },
        // 编辑报告
        async edit() {
            let data = {
                id: this.parentId,
                content: this.kimi_detail,
            }
            let resData = await this.$Api.kimi.editRreport(data);
            console.log('编辑报告:', resData);
            if (resData.code == 200 && resData.data === true) {
                this.$notify.success({
                    title: "编辑成功",
                });
            }
        }
    },
    mounted() {
        if (this.$route.query.reportType == 3) {
            this.reportUserList();
        } else {
            this.reportAiLast();
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./less/view_kimi.less');
</style>